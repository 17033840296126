<template>
  <div>
    <h1>Tutorials</h1>
    <h2>Use CURL to request Digi-Key Electronics' API</h2>
    <p>
      Curl (stands for "Client URL") is a popular cross-platform (Windows/Linux/Mac...) command-line tool that allows to do HTTP requests.
      In our case we will use it to generate the access token and perfom the firsts Digi-Key's API requests.<br/>
    </p>
    <p>
      You can download Curl from the official website <a href="https://curl.haxx.se/">https://curl.haxx.se/</a>, go
      to "Download", then select the package that fit your operating system or download an executable wizard.<br/>
    </p>
    <p>
      We will use this tool to perform simple GET and POST requests that will help to understand how the API works
      and confirm your network allows such requests.<br>
    </p>
    <p>
      For all of the Curl requests below, you can add the parameter "-v" to get
      all the details of the transaction and debug the issue/error you may face.
    </p>
    <p>
      The video below will help you to understand how this page works.
    </p>
    <div class="embed-responsive embed-responsive-16by9" style="max-width: 560px; margin: 0 auto;">
      <iframe src="https://www.youtube.com/embed/e5aI9dLl1Xg" class="embed-responsive-item" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <h3>Your app information</h3>
    <p>
      I assume you have already created your app (sandbox or production), if not, please read carefully the documentation
      here: <a href="https://developer.digikey.com/documentation">https://developer.digikey.com/documentation</a>.
      For the tests below, I recommend to add a Callback URL that do not refer to an existing webpage in order to manualy
      capture the authentication code (example: <a href="https://127.0.0.1">https://127.0.0.1</a>).
    </p>
    <p>
      Please fill the fields below that are needed to build the link to the authentication server and
      generate the curl command-lines.
    </p>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="curl-env-radio" class="mt-sm-2 mr-sm-2">API environment</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-radio-group
          id="curl-env-radio"
          v-model.trim="curl.env"
          :options="envs"
          buttons
          button-variant="outline-primary"
          name="radios-btn-default"
        ></b-form-radio-group>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="curl-client-id-input" class="mt-sm-2 mr-sm-2">Client ID</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-input
          id="curl-client-id-input"
          v-model.trim="curl.clientID"
          placeholder="Copy/Paste your Client ID"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="curl-client-secret-input" class="mt-sm-2 mr-sm-2">Client Secret</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-input
          id="curl-client-secret-input"
          v-model.trim="curl.clientSecret"
          placeholder="Copy/Paste your Client Secret"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="2" md="3" sm="4">
        <label for="curl-callback-url-input" class="mt-sm-2 mr-sm-2">Callback URL</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-input
          id="curl-callback-url-input"
          v-model.trim="curl.redirectURI"
          placeholder="Copy/Paste your Callback URL"></b-form-input>
      </b-col>
    </b-row>
    <h3>Get the authentication code</h3>
    <p>
      You will have to authenticate (if not already done) to the Digi-Key Electronics authentication server.
      After a successful log in and approuval to tie your account to the app, you will be redirected to your
      Callback URL that will content the authentication code.
    </p>
    <p>
      Click on the following URL or Copy/Past to your browser:
    </p>
    <p>
      <a
        :href="authenticationURL"
        target="_blank">
        <code style="font-weight: bold; font-size: 16px; word-break: break-all;">
          {{ authenticationURL }}
        </code>
      </a>
    </p>
    <b-button
      v-show="canCopyToClipboard"
      size="sm"
      @click="updateClipboard(authenticationURL)"
      :disabled="!curl.clientID || !curl.redirectURI"
      variant="info">
      Copy to clipboard</b-button>
    <h3>Get the access token from the authentication code</h3>
    <p>
      The code you received in the previous step (in the callback URL) can be used to generate
      one single access token to requests the APIs. This code is 8 characters long
      and is located in the url you are redirect to after a successful login.
    </p>
    <p>
      As an example: https://127.0.0.1/?code=<b><mark>TVc4jD81</mark></b>&scope=&state=null.
    </p>
    <p>
      Copy/Past this code below to get the Curl command line that will retrieve your access token.
    </p>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="app-authentication-code-input" class="mt-sm-2 mr-sm-2">Code</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-input
          id="app-authentication-code-input"
          v-model.trim="curl.authenticationCode"
          placeholder="Copy/Paste the authentication code"></b-form-input>
      </b-col>
    </b-row>
    <p>
      <code style="font-weight: bold; font-size: 16px; word-break: break-all;">
        {{ curlToken }}
      </code>
    </p>
    <b-button
      v-show="canCopyToClipboard"
      size="sm"
      @click="updateClipboard(curlToken)"
      :disabled="!curl.clientID || !curl.clientSecret || !curl.redirectURI || !curl.authenticationCode"
      variant="info">
      Copy to clipboard</b-button>
    <h3>Send you first product details request</h3>
    <p>
      With the access token, you can now perform requests to the APIs.
      Here is an example of a product details request (a GET request) that will return the content
      of the Digi-Key Electronics product webpage.
    </p>
    <p>
      Copy/Past your access token below to get the Curl command line that will perform a keyword search.
    </p>
    <b-row class="mb-4">
      <b-col lg="2" md="3" sm="4">
        <label for="app-token-input" class="mt-sm-2 mr-sm-2">Access Token</label>
      </b-col>
      <b-col md="9" sm="8">
        <b-form-input
          id="app-token-input"
          v-model.trim="curl.token"
          placeholder="Copy/Paste the access token"></b-form-input>
      </b-col>
    </b-row>
    <p>
      <code style="font-weight: bold; font-size: 16px; word-break: break-all;">
        {{ curlKeywordSearch }}
      </code>
    </p>
    <b-button
      v-show="canCopyToClipboard"
      size="sm"
      @click="updateClipboard(curlKeywordSearch)"
      :disabled="!curl.clientID || !curl.token"
      variant="info">
      Copy to clipboard</b-button>
    <p>
      You will receive a non formated JSON response. You can use any online JSON formatter to make the response human readable (example: <a href="https://jsonformatter-online.com" target="_blank">https://jsonformatter-online.com</a>).
    </p>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      curl: {
        appName: null,
        clientID: null,
        clientSecret: null,
        redirectURI: null,
        authenticationCode: null,
        token: null,
        env: 'Sandbox'
      },
      envs: ['Sandbox', 'Production'],
      canCopyToClipboard: false
    }
  },
  created () {
    try {
      navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
          this.canCopyToClipboard = true
        }
      })
    } catch (error) {
      this.canCopyToClipboard = false
    }
  },
  computed: {
    serverDomain () {
      return this.curl.env === 'Production' ? 'https://api.digikey.com' : 'https://sandbox-api.digikey.com'
    },
    authenticationURL () {
      return this.serverDomain + `/v1/oauth2/authorize?response_type=code&client_id=${this.curl.clientID || 'YourClientID'}&redirect_uri=${this.curl.redirectURI || 'YourRedirectURI'}`
    },
    tokenURL () {
      return this.serverDomain + '/v1/oauth2/token'
    },
    keywordSearchURL () {
      return this.serverDomain + '/Search/v3/Products/'
    },
    curlToken () {
      return `curl -d "code=${this.curl.authenticationCode || 'YourCode'}&client_id=${this.curl.clientID || 'YourClientID'}&client_secret=${this.curl.clientSecret || 'YourClientSecret'}&redirect_uri=${this.curl.redirectURI || 'YourRedirectURI'}&grant_type=authorization_code" -X POST ${this.tokenURL}`
    },
    curlKeywordSearch () {
      return `curl -H "X-DIGIKEY-Client-Id: ${this.curl.clientID || 'YourClientId'}" -H "Authorization: Bearer ${this.curl.token || 'YourAcessToken'}" -H "X-DIGIKEY-Locale-Site: US" -H "X-DIGIKEY-Locale-Language: en" -H "X-DIGIKEY-Locale-Currency: USD" -H "X-DIGIKEY-Locale-ShipToCountry: us" -H "X-DIGIKEY-Customer-Id: 0" ${this.keywordSearchURL}p5555-nd`
    }
  },
  methods: {
    updateClipboard (text) {
      navigator.clipboard.writeText(text).then(() => {}, () => { alert('Failed to copy to clipboard. Please copy it manualy') })
    }
  }
}
</script>
