<template>
  <div>
    <h1>Migrating from API V2 to V3</h1>
    <p>
      This video explains the main difference between Digi-Key Electronics API V2 and V3, and
      how to update your software to use this new version.
    </p>
    <div class="embed-responsive embed-responsive-16by9" style="max-width: 560px; margin: 0 auto;">
      <iframe src="https://www.youtube.com/embed/as_bOQjFRNI" class="embed-responsive-item" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      curl: {
        appName: null,
        clientID: null,
        clientSecret: null,
        redirectURI: null,
        authenticationCode: null,
        token: null,
        env: 'Sandbox'
      },
      envs: ['Sandbox', 'Production'],
      canCopyToClipboard: false
    }
  },
  created () {
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        this.canCopyToClipboard = true
      }
    })
  },
  computed: {
    serverDomain () {
      return this.curl.env === 'Production' ? 'https://api.digikey.com' : 'https://sandbox-api.digikey.com'
    },
    authenticationURL () {
      return this.serverDomain + `/v1/oauth2/authorize?response_type=code&client_id=${this.curl.clientID || 'YourClientID'}&redirect_uri=${this.curl.redirectURI || 'YourRedirectURI'}`
    },
    tokenURL () {
      return this.serverDomain + '/v1/oauth2/token'
    },
    keywordSearchURL () {
      return this.serverDomain + '/Search/v3/Products/'
    },
    curlToken () {
      return `curl -d "code=${this.curl.authenticationCode || 'YourCode'}&client_id=${this.curl.clientID || 'YourClientID'}&client_secret=${this.curl.clientSecret || 'YourClientSecret'}&redirect_uri=${this.curl.redirectURI || 'YourRedirectURI'}&grant_type=authorization_code" -X POST ${this.tokenURL}`
    },
    curlKeywordSearch () {
      return `curl -H "X-DIGIKEY-Client-Id: ${this.curl.clientID || 'YourClientId'}" -H "Authorization: Bearer ${this.curl.token || 'YourAcessToken'}" -H "X-DIGIKEY-Locale-Site: US" -H "X-DIGIKEY-Locale-Language: en" -H "X-DIGIKEY-Locale-Currency: USD" -H "X-DIGIKEY-Locale-ShipToCountry: us" -H "X-DIGIKEY-Customer-Id: 0" ${this.keywordSearchURL}p5555-nd`
    }
  },
  methods: {
    updateClipboard (text) {
      navigator.clipboard.writeText(text).then(() => { alert('Copied to your clipboard.') }, () => { alert('Failed to copy to clipboard.') })
    }
  }
}
</script>
